import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader/faqheader'
import FaqSectionOne from '../containers/faq/section-two'
// import FaqSectionTwo from '../containers/faq/section-two'
import ContactArea from '../containers/contact-us/contact-form-area'

const FAQPage = ({ pageContext, location }) => (
    <Layout location={location}>
        <SEO 
            title="Faq"
            description="Software development is our expertise. We gladly share our knowledge with you to get the best results for your specific business requirement."
        />
        <Header />
        <PageHeader
            pageContext={pageContext}
            location={location}
            title="FAQ"
        />
        <main className="site-wrapper-reveal">
            <FaqSectionOne />
            {/* <FaqSectionTwo /> */}
            <ContactArea />
        </main>
        <Footer />
    </Layout>
)

export default FAQPage